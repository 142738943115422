<template>
  <v-container
    class="fill-height mt-3"
    fluid
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <v-row>
        <v-col>
          <allergen-group-table title="Groups" />
        </v-col>
        <v-col>
          <allergen-table title="Allergens" />
        </v-col>
      </v-row>
    </transition>
  </v-container>
</template>

<script>
  export default {
    components: {
      AllergenTable: () => import('@/components/tables/AllergenTable'),
      AllergenGroupTable: () => import('@/components/tables/AllergenGroupTable'),
    },
    // data: () => ({
    //   search: null,
    //   rightDrawerOpen: false,
    //   rightDrawerData: null,
    // }),
    // methods: {
    //   rightDrawerToggle (data, open) {
    //     this.rightDrawerOpen = open || !this.rightDrawerOpen
    //     this.rightDrawerData = data
    //   },
    // },
  }
</script>

<style>
.v-data-table__wrapper{
    max-height:calc(100vh - 244px) !important;
}
</style>
